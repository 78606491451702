
@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');


/* CSS RESET */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section { display: block; }
html  { font-size: 62.5%; }
body { line-height: 1; font-family: Arial, Helvetica, Verdana; font-size: 1rem; min-width: 320px; background: #ffffff; }
a, a:hover, a:active, a:focus { text-decoration: none; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0; }

/* Global */
*             { box-sizing: border-box; outline: none; }
html          { width: 100%; height: 100%; }
body          { overflow-x: hidden; font-family: Arial, Helvetica, Verdana; }
.Main         { min-height: calc(100vh - 150px); max-width: 100%; display: flex; }
.Content      { flex: 1 1; min-height: 500px; }
.Container    { max-width: 1220px; margin: auto; height: 100%; padding-left: 20px; padding-right: 20px; position: relative; }
h1            { font-size: 3.0rem; padding: 0px; position: relative; margin-bottom: 40px; font-family: 'Arvo', serif, Arial, Helvetica, Verdana; line-height:1.3em; }
h2            { font-size: 2.4rem; padding: 0px; position: relative; margin-bottom: 30px; font-family: Arial, Helvetica, Verdana; }
h3            { font-size: 1.6rem; padding: 0px; position: relative; margin-bottom: 30px; color: #767676; font-family: Arial, Helvetica, Verdana; }
p             { color: #787e80; font-size: 1.4rem; font-weight:400; line-height: 2.6rem; padding: 0px; margin-bottom: 12px; }
ul            { margin-bottom: 20px; }
ul li         { color: #787e80; font-size: 1.4rem; line-height: 2.2rem; margin-bottom: 10px; position: relative; }
strong        { font-weight: bold; }

a             { color: #222; text-decoration: none; outline: none; }
a:hover       { text-decoration: underline; }
p a           { text-decoration: underline; }

ul    {margin:0px 0px 12px 16px; padding:0px;}
ol    {margin:0px 0px 12px 16px; padding:0px;}
ul li {margin:0px 0px 6px 16px; font-size: 1.4rem;}
ol li {margin:0px 0px 6px 16px; font-size: 1.4rem;}

.LineSpacer1 {height:2px;   font-size:0.1rem; clear:both;}
.LineSpacer2 {height:5px;   font-size:0.1rem; clear:both;}
.LineSpacer3 {height:10px;  font-size:0.1rem; clear:both;}
.LineSpacer4 {height:15px;  font-size:0.1rem; clear:both;}
.LineSpacer5 {height:25px;  font-size:0.1rem; clear:both;}
.LineSpacer6 {height:40px;  font-size:0.1rem; clear:both;}
.LineSpacer7 {height:80px;  font-size:0.1rem; clear:both;}
.LineSpacer8 {height:200px; font-size:0.1rem; clear:both;}

.AddressBlock p {margin-bottom: 0px;}

.BottomFix   {clear:both; height:0px; font-size:0px; line-height:0px;}

.Btn                              { display: inline-block; height: 44px; }
.Btn:hover                        { opacity: 0.8; }
.Btn a, .Btn span, .Btn input     { background-color: #36984a; color: #ffffff; display: inline-block; text-align: center;  padding: 14px 20px; border: none; cursor: pointer; font-size: 1.3rem; line-height:1.5rem; letter-spacing: 2px; font-family: Arial, Helvetica, Verdana, Sans-Serif; font-weight:700; text-transform:uppercase; text-decoration: none; min-width: 220px;}
.Btn.FullWidth                    { width: 100%; }
.Btn.NoBackground,
.Btn.NoBackground:hover           { background-image: none }
.Btn.BtnDisabled                  { opacity: 0.5; background-image: none; }
.Btn.BtnDisabled:hover            { background-image: none; }
.Btn.BtnDisabled span             { cursor: default }

.BtnBlue          { border: 1px solid #0265bf; background:#0265bf; cursor:pointer; transition:all 0.3s ease; }
.BtnBlue a        { text-decoration: none; }
.BtnBlue:hover    { border: 1px solid #014a8c; background:#014a8c; }

.BtnRed           { border: 1px solid #db0011; cursor: pointer; background:url('../images/Icon_Chevron.png') no-repeat right #db0011; background-size: 18px 12px; }
.BtnRed:hover     { border: 1px solid #a8000b; cursor: pointer; background:url('../images/Icon_Chevron.png') no-repeat right #a8000b; background-size: 18px 12px; }
.BtnRed2          { border: 1px solid #db0011; cursor: pointer; background:url('../images/Icon_Chevron_02.png') no-repeat left #db0011; background-size: 18px 12px; }
.BtnRed2:hover    { border: 1px solid #a8000b; cursor: pointer; background:url('../images/Icon_Chevron_02.png') no-repeat left #a8000b; background-size: 18px 12px; }
.BtnGrey          { border: 1px solid #767676; cursor: pointer; background:url('../images/Icon_Chevron.png') no-repeat right #767676; background-size: 18px 12px; }
.BtnGrey:hover    { border: 1px solid #585858; cursor: pointer; background:url('../images/Icon_Chevron.png') no-repeat right #585858; background-size: 18px 12px; }
.BtnGrey2         { border: 1px solid #767676; cursor: pointer; background:#767676; transition:all 0.3s ease; }
.BtnGrey2:hover   { border: 1px solid #585858; cursor: pointer; background:#585858; }
.BtnGrey2 a       { text-decoration: none; }
.BtnBlack         { background-color: #000000; border: 1px solid #000000; cursor: pointer;  }
.BtnWhite         { background-color: #ffffff; border: 1px solid #c7cbcc; cursor: pointer;  }
.BtnWhite span    { color: #333; }
.BtnWhite a       { color: #333; text-decoration: none; }

.BtnSideBySide .BtnBlock    {float:left; margin-right:15px;}

.HeightPadding    { padding-top: 40px; padding-bottom: 40px; }

.HeaderRow                { display: flex; align-items: center; flex-wrap: nowrap; }
.HeaderRow > div          { width: 100%; margin-top: 7px; text-align: left; width: 100%; }
.HeaderRow .MiddleBlock   { text-align: left; }
.HeaderRow .LeftBlock     { width: 40px; }
.HeaderRow .RightBlock    { width: 0px; }

.Row            { display: flex; width: 100%; height: 100%; }
.AlignCenter    { justify-content: center; }
.SelfMiddle     { align-self: center; }
.AlignBetween   { justify-content: space-between; }

.DarkText       { color:#282828; font-size:1.6rem; }

.Flex         { display: flex; flex-wrap: wrap; flex-direction: row; justify-content: space-between; }

.Relative     { position: relative; }

.HomeLogo     { position:relative; right:7px; background: url('../images/StrengthsLite_Logo.png') no-repeat center; background-size: cover; width: 254px; height: 80px; margin-bottom:15px; }



.Checkbox { position: absolute; opacity: 0; }
.Checkbox + label { position: relative; cursor: pointer; padding: 0; }
.Checkbox + label:before { content: ''; margin-right: 10px; display: inline-block; vertical-align: text-top; width: 20px; height: 20px; background: white; border: 1px solid #E0E0E0; }
.Checkbox:disabled + label { color: #b8b8b8; cursor: auto; }
.Checkbox:disabled + label:before { box-shadow: none; background: #ddd; }
.Checkbox:checked + label:after { content: ''; position: absolute; left: 4px; top: 4px; background: #A5A5A5; width: 14px; height: 14px; }

body.popupOpen                { overflow: hidden; }

.InputGroup                   { padding-bottom: 37px; }
.InputGroup label             { color: #282828;	font-family: Arial, Helvetica, Verdana; font-size: 0.9rem; text-transform: uppercase; font-weight: 600; letter-spacing: 1px; padding-bottom: 15px; display: block; }
.InputGroup .Input            { border: 1px solid #D5D1D1;	background-color: #eee; padding: 0 20px; height: 44px; width: 100%; }
.InputGroup .Input:focus      { box-shadow: none; outline: none; }
.InputGroup.Cell:first-child  { padding-right: 15px; }
.InputGroup.Cell:last-child   { padding-left: 15px; }
.InputGroup .ResponseMessage  { color: #dc4444; font-size: 0.7rem; margin-top: 5px; }
.InputGroup.Highlighted .Input{ background-color: #fff7f7; border: 1px solid #dc4444; }

.WhiteBox                     { padding: 30px 30px 1px 30px; background: #ffffff;  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.2); border: 1px solid #e0e0e0; }

/* Cookies */
.CookiesPolicy              { background: #404040; padding: 23px 0; text-align: left; color: #fff; font-size: 1.3rem; }
.CookiesPolicy img          { width: 13px; position: relative; top: 2px; margin-right: 10px; }
.CookiesPolicy a            { color: #fff; text-decoration: underline; }
.CookiesPolicy .Close       { width: 20px; height: 20px; display: block; cursor: pointer; background: url('../images/Icon_Cross.png') no-repeat center; background-size: cover; position: absolute; top: -4px; right: 15px; }
.CookiesPolicy .Container   { padding-right: 50px; }

/* Header */
.HeaderFixed .Header          { /*position: fixed; top: 0; left: 0;*/ }
.Header                       { height: 100px; width: 100%; top: 0; z-index: 4; background: #ffffff; text-align:center; border-bottom: 1px solid #ffffff; position: relative; box-sizing: border-box;}
.Header .Languages            { width: 150px; }
.Header .Languages__control   { border-radius: 0; }
.Header .Languages__indicator { transition: .2s ease-in-out; }
.Header .Languages__control--menu-is-open .Languages__indicator { transform: rotate(180deg) }
.Header .Languages__indicator-separator { display: none; }
.Header .Languages__option    { display: flex; align-items: center; font-size: 1.5rem; }
.Header .Languages__menu      { border-radius: 0; }
.Header .Languages__single-value { padding-left: 27px; font-size: 1.5rem; line-height: 1.3; }
.Header .Languages__value-container img { width: 20px; height: 20px; border-radius: 50%; }
.Header .Languages__option img{ border-radius: 50%; width: 20px; height: 20px; margin-right: 7px; }
.Header .Settings             { display: flex; }
.Header .Logo img             { height: 82px; }
.Header .BtnPlusInfo          { cursor: pointer; }
.Header .BtnPlusInfo svg      { fill: #D5D1D1; border: 1px solid #D5D1D1; padding: 9px; box-sizing: border-box; border-radius: 20px; width: 40px; height: 40px; }
.Header .BtnPlusInfo svg:hover{ opacity: 0.8; }


/* Cookie bar options */
.CookieBar .Strengths .Placeholder    { height: 255px; }

.BtnRegister              { position: absolute; top: -60px; right: 20px; z-index: 20; }

/* Footer */
.SiteFooter                     { background: #ffffff; border-top: 1px solid #ffffff; }
.SiteFooter .Row                { height: 149px; flex-direction: column; justify-content: center; }
.SiteFooter .FooterLinks        { font-size: 0.8rem; }
.SiteFooter ul                  { margin: 15px 0px 0px 0px; text-align: center; }
.SiteFooter ul li               { display: inline-block; margin-right: 15px; margin-left: 0px; margin-bottom: 0; color: #787e80; font-size: 1.1rem; }
.SiteFooter ul li:last-child    { margin-right: 0px; }
.SiteFooter ul li a             { color: #787e80; font-size: 1.1rem; }
.SiteFooter ul li a:hover       { text-decoration: none; }
.SiteFooter ul li span          { cursor: pointer; }
.SiteFooter .Credits            { text-align: right; font-size: 1.1rem; color: #787e80; margin-top: 20px; position: relative; }
.SiteFooter .ImgFooter          { display: block; width: 160px; }
.SiteFooter .ImgFooter img      { display: block; width: 100%;  }


/* Structure Table */
.StructureTable               { display: table;  width: 100%; }
.StructureTable .RightCell    { display: table-cell; vertical-align: top; }
.StructureTable .LeftCell     { display: table-cell; vertical-align: top; width: 360px; box-sizing: border-box; padding-left: 40px; text-align: center; }

.ImageColumn_01               { background: url('../images/Register.png') no-repeat center; background-size:cover; width: 320px; height: 350px; }
.ImageColumn_02               { background: url('../images/Img_ColumnRight_02.jpg') no-repeat center; background-size:cover; width:100%; height: 430px; margin-top: 100px; }
.ImageColumn_03               { background: url('../images/Img_ColumnRight_04.jpg') no-repeat center; background-size:cover; width:100%; height: 260px; margin-top: 100px; }

/* Welcome */
.Welcome                      {}
.Welcome .Table               { display: table; width:100%;}
.Welcome .Table .Cell         { display: table-cell; vertical-align: middle; }
.Welcome .Table .Cell_01      { padding: 65px 0px 35px 0px;}
.Welcome .Table .Cell_02      { background: url('../images/Img_Hexagon.png') no-repeat bottom; background-size:contain; width:320px;}
.Welcome .Title               { color: #000000; font-size: 5.0rem; font-family: Arial, Helvetica, Verdana; margin-bottom: 20px; }
.Welcome .Subtitle            { color: #ffffff; font-size: 4.4rem; font-family: Arial, Helvetica, Verdana; line-height: normal; margin: 0px 0px 0px -30px; padding: 10px 100px 20px 30px; background: url('../images/Bg_IntroText.png') no-repeat center; background-size:cover; display:inline-block;}
.Welcome .Intro               { font-size:1.8rem; color: #282828; font-weight: 600; font-family: Arial, Helvetica, Verdana; line-height: 2.6rem; }
.Welcome .ImageVideo          { background: url('../images/Img_VideoWelcome_01.jpg') no-repeat center; background-size:cover; width:100%; height:180px; }
.Welcome .VideoWrapper        { position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0; /*box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.2); border: 1px solid #e0e0e0;*/ }
.Welcome .VideoWrapper iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.Welcome .VideoIntro                  { width: 100%; }
.Welcome .VideoIntro .VideoText       { font-family: Arial, Helvetica, Verdana; font-size: 1.4em; font-weight: 600; text-transform: uppercase; letter-spacing: 2px; color: #c88064; margin-bottom: 20px; text-align: left; }

.BtnWelcomePage                 { margin-top: 40px; display: flex; justify-content: flex-end; }

/* Register*/
.BtnRegisterPage                { margin-top: 215px; }
.TopSpaceRegisterPage           { margin-top: 215px; }

/* Form */
.Form h2                                    { margin-bottom: 40px; }
.Form .FormBox                              { margin-bottom: 30px; }
.Form .FormBox.W47                          { width:47%; float:left; }
.Form .FormBox .FormLabel                   { font-size: 1.2rem; line-height:1.3em; margin-bottom:15px; color: #282828; font-weight: 400; text-transform: uppercase; font-family: Arial, Helvetica, Verdana, sans-serif; letter-spacing: 1px; }
.Form .FormBox .FormField                   { position:relative; }
.Form .FormBox .FormField.Disabled::after   { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: none; }
.Form .FormBox .FormField .Icon             { position: absolute; top: 0px; left: 0px; width: 40px; height: 40px; display: block; }
.Form .FormBox .FormField .Input            { font-size: 13px; line-height:1.3em; font-weight:400; color: #282828; width:100%; background-color:#eeeeee; padding:0px 10px 0px 10px; height:44px; border: 1px solid #d5d1d1; box-sizing:border-box; font-family: Arial, Helvetica, Verdana, sans-serif; -webkit-appearance: none; -moz-appearance: textfield; }
.Form .FormBox .FormField .ResponseMessage  { color: #db0011; padding-top: 5px; font-size: 1.1rem; display: block; }

.Form .FormBox.Highlighted .FormField .Input    {border:1px solid #ffb6b6;}

.Form2                                      { padding: 0px; box-shadow:none; border:none; }
.Form2 .FormBox .FormLabel                  { width: 35%; line-height: 1.0em; }
.Form2 .FormBox .FormField                  { width: 45%; }
.Form2 .FormBox .FormButton                 { width: 20%; display: table-cell; vertical-align: middle; }
.Form2 .FormBox .FormButton .BtnSend        { display:block; margin-left:20px; }
.Form2 .FormBox .FormButton .BtnSent        { background: #d3f8d6; border-color: #d3f8d6; cursor: default; }
.Form2 .FormBox .FormButton .BtnSent > span { color: #41bc51; display: flex; align-items: center; justify-content: center; cursor: default; }
.Form2 .FormBox .FormButton .BtnSent .Icon  { width: 15px; height: 15px; margin-right: 7px; background: url(../images/Icon_Valid.svg); background-size: cover; }

.BoxSpacerW6                                { width:6%; height:1px; float:left; }

.BottomLinks                                {margin-bottom:30px;}
.BottomLinks .BtnLeft                       {float:left;}
.BottomLinks .BtnRight                      {float:right;}

/* Privacy Policy */
.PrivacyPolicy ul li:after  { position: absolute; content: ''; left: -11px; top: 13px; transform: translate(0, -50%); width: 4px; height: 4px; border-radius: 50%; }
.PrivacyPolicy ul           { padding-left: 10px; }

/* Breadcrumb */
.Breadcrumb               { font-size: 1.2rem; padding-bottom: 20px; text-align:right; }
.Breadcrumb a             { color:#333333; }
.Breadcrumb span          { margin-left: 5px; margin-right: 5px; }

/* Strengths */
.StrengthsTable                                                     { display: table; width: 100%; height: calc(100vh - 250px); }
.StrengthsTable .StrengthsContent                                   { position: relative; display: table-cell; vertical-align: middle; }
.StrengthsTable .StrengthsContent .StrengthsBlock                   { position: relative; }
.StrengthsTable .StrengthsContent .StrengthsBlock .StrengthsInner   { position: relative; }

.Strengths                                { }
.Strengths .Placeholder                   { height: 195px; }
.Strengths .TopStrengths                  { width: 100%; z-index: 3; padding: 20px 0px 0px 0px; text-align:center; background-color: #ffffff;}
.Strengths .TopStrengths .Container       { max-width: 1220px; }
.Strengths .TopStrengths .Container .BtnPlusInfo      { position: absolute; top: -69px; right: 20px; }
.Strengths .TopStrengths .Container .BtnPlusInfo svg  { fill: #333; background: #fff; border: 1px solid #d5d1d1; padding: 9px; box-sizing: border-box; border-radius: 20px; width: 40px; height: 40px; }
.Strengths .TopStrengths .Subtitle        { font-size: 1.4rem; line-height: 2.2rem ; }
.Strengths .TopStrengths .Subtitle_01     { padding-right: 60px; }
.Strengths .TopStrengths .Subtitle_02     { padding-left: 60px; }
.Strengths .TopStrengths .Title           { font-size: 1.8rem; padding: 0; margin: 0; }
.Strengths .TopStrengths .Title:after     { display: none; }
.Strengths .TopStrengths .Table           { display: table; width: 100%; table-layout:fixed; background-color: #ffffff;  }
.Strengths .TopStrengths .Table .Cell     { display: table-cell; vertical-align: middle; }
.Strengths .TopStrengths .TSText          { margin: 0px; padding: 0px; }

.Strengths .PickedCards                   { position: relative; text-align: left; display: inline-block; margin-bottom: 10px; }
.Strengths .PickedCards .Card             { display: inline-block; position: relative; margin-right: 7px; height: 100px;	width: 70px; border: 1px dashed #D5D1D1; background-color: #fff; border-radius: 4px; }
.Strengths .PickedCards .Card:last-child  { margin-right: 0; }
.Strengths .PickedCards .Card.Disabled    { }

.Strengths .ButtonsBottom                 { width: 100%; text-align: center; padding: 10px 0px 10px 0px; box-sizing: border-box; /*position: sticky;*/ z-index: 10; bottom: 0px; background: rgba(255, 255, 255, 0.8); }
.Strengths .ButtonsBottom .BtnApply       { display: inline-block; } 

/* Top strengths cards */
.Strengths .NewCards                          { width: 100%; height: 100%; position: absolute; top: 0; left: 0; }
.Strengths .NewCards .Card                    { transform: none !important; position: relative; text-align: center; }
.Strengths .NewCards .CardIcon img            { height: 45px; margin-top: 5px; }
.Strengths .NewCards .BackgroundIcon          { display: none; }
.Strengths .NewCards .RemoveCard              { cursor: pointer; position: absolute; bottom: -10px; z-index: 2; left: 50%; transform: translateX(-50%);}
.Strengths .NewCards .RemoveCard svg          { width: 20px; height: 20px; fill: #333; background: #fff; border: 1px solid #d5d1d1; padding: 5px; box-sizing: border-box; border-radius: 20px;}
.Strengths .NewCards .Title                   { font-size: 0.9rem; color: #fff; margin-top: 5px; display: table; height: 20px; width: 100%; }
.Strengths .NewCards .Title .CellSmall        { display:table-cell; vertical-align: middle; text-align: center; }

.Strengths .PickedCards .NewCards .Color0 { background: #2B9B52; border: 1px solid #2B9B52; }
.Strengths .PickedCards .NewCards .Color1 { background: #E28724; border: 1px solid #E28724; }
.Strengths .PickedCards .NewCards .Color2 { background: #0265BF; border: 1px solid #0265BF; }
.Strengths .PickedCards .NewCards .Color3 { background: #A32986; border: 1px solid #A32986; }

.Strengths .CardsIntro        { position: absolute; width: 100%; }
.Strengths .CardsTitle        { font-size: 2.4rem; text-align: center; margin: 0; padding: 15px 0 15px; font-family: 'Arvo', Arial, Helvetica, Verdana;}
.Strengths .CardsTitle svg    { margin-right: 10px; }
.Strengths .CardsSubtitle     { text-align: center; text-transform: uppercase; letter-spacing: 2px; font-size: 1.4rem; margin-bottom: 55px; }

.Strengths .Container                    { position: relative; }

/* Cards in list view before dragging */
.Strengths .CardCell                        { margin: 0 10px; height: 100%; width: 210px; transition: .3s ease-in-out; transform: translate(-200px, 0); opacity: 0; }
.Strengths .entered                         { transform: translate(0, 0); opacity: 1; }
.Strengths .swiper-slide                    { width: auto; }
.Strengths .swiper-button-disabled          { display: none; }

.CardElement                     { display: block; background-color: #fff; overflow: hidden; position: relative; background-size: 100%; max-width: 210px; min-height: 290px; height: 100%; width: auto; border-radius: 4px; padding: 15px; box-sizing: border-box; }
.CardElement .CardTitle          { text-align: center; font-size: 1.6rem; position: relative; margin-top: 65px; margin-bottom: 100px; font-weight: 400; }
.CardElement .CardInfo           { text-align: center; font-size: 1.1rem; line-height: 1.3; position: relative; color: #ffffff; font-family: Arial, Helvetica, Verdana; margin-bottom: 0px; }
.CardElement .StrengthIcon       { text-align: center; margin-top: 20px; position: relative; }
.CardElement .StrengthIcon svg   { height: 94px; width: 94px; }
.CardElement .CardInfo           { display: block;}
.CardElement .Buttons            { text-align: center; display: block;  }
.CardElement .Icon.Info          { cursor: pointer; position: absolute; top: 6px; right: 6px; width: 25px; height: 25px; border-radius: 50%; display: inline-block; text-align: center; padding: 6px 0;  border: 1px solid #fff; }
.CardElement .Icon.Info svg      { height: 10px; fill: #fff !important; }
.CardElement .IconContainer      { position: absolute; top: -6px; left: -6px; right: -6px; bottom: -6px; }
.CardElement .Icon.Add           { position: absolute; cursor: pointer; top: 20px;  left: 50%; transform:translateX(-50%); width: 45px; height: 45px; border-radius: 50%; display: inline-block; background: #fff; text-align: center; padding-top: 12px; border: 1px solid #ffffff; margin-right: 10px; }
.CardElement .Icon.Add svg       { height: 20px; }

.CardElement .BackgroundIcon     { position: absolute; width: 100%; bottom: -20px; left: 0; z-index: 0; }
.CardElement .BackgroundIcon img { width: 100%; }

/* More info */

.Overlay                                  { position: absolute; z-index: 10; }
.Overlay:after                            { content: ''; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.6); z-index: 3; }
.Overlay.exited                           { display: none; }
.Overlay.entering                         { display: block; opacity: 0; transition: 0.2s ease-in-out; }
.Overlay.exiting                          { display: block; opacity: 0; transition: 0.2s ease-in-out; }
.Overlay.entered                          { display: block; opacity: 1; transition: 0.2s ease-in-out; }

.Popup                                    { position: fixed; z-index: 11; top: 0; left: 0; width: 100%; height: 100%; }
.Popup.exited                             { display: none; }
.Popup.entering                           { display: flex; opacity: 0; transition: 0.2s ease-in-out; }
.Popup.exiting                            { display: flex; opacity: 0; transition: 0.2s ease-in-out; }
.Popup.entered                            { display: flex; opacity: 1; transition: 0.2s ease-in-out; }

.Modal                                    { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 4; }
.Popup .MoreInfo .CardElement             { width: 100%; position: absolute; z-index: 5; display: block; overflow: hidden; box-sizing: border-box; }
.Popup .MoreInfo .CardsTitle              { font-size: 3.0rem; text-align: center; margin-bottom: 30px; }
.Popup .MoreInfo .CardInfo                { text-align: center; line-height: 1.3; }
.Popup .MoreInfo .CardInfo                { display: block;}
.Popup .MoreInfo .Buttons                 { text-align: center; display: block;}
.Popup .MoreInfo .CardBack .Icon          { width: 40px; height: 40px; border-radius: 50%; display: inline-block; text-align: center; padding: 12px 0; border: 1px solid #D5D1D1; margin-right: 10px; }
.Popup .MoreInfo .Icon:last-child         { margin-right: 0; }
.Popup .MoreInfo .Icon svg                { height: 12px; }
.Popup .MoreInfo .CardsSubTitle           { text-align: left; font-size:1.5rem; line-height: 1.5; margin: 0px 0px 20px 0px ; color: #ffffff; }
.Popup .MoreInfo .CardsDescription        { color: #ffffff; font-size:1.2rem; font-weight: 600; margin: 25px 0 15px 0; text-align: left; font-family: Arial, Helvetica, Verdana; text-transform: uppercase; letter-spacing: 2px; }
.Popup .MoreInfo .BackgroundIcon          { position: absolute; width: 100%; left: 0; z-index: -1; }
.Popup .MoreInfo .BackgroundImage         { height: 100%; padding: 40px; background-size: 500%; background-repeat: no-repeat; background-position: top center; min-width: 320px; }
.Popup .MoreInfo .BackgroundImage .CardsTitle {color:#ffffff;}
.Popup .MoreInfo .Icon.Add svg            { height: 20px; }
.Popup .MoreInfo ul                       { padding-left: 0; margin-bottom: 0px; }
.Popup .MoreInfo ul li                    { margin-top: 15px; font-size:1.3rem; line-height: 1.4; color: #ffffff; position: relative; padding-left: 5px; text-align: left; }
.Popup .MoreInfo .CardsText               { margin-top: 15px; padding-bottom: 20px; font-size:1.3rem; line-height: 1.4; color: #ffffff; text-align: left; }
.Popup .CloseModal                        { width: 28px; height: 28px; padding: 8px; position: absolute; top: 16px; right: 16px; cursor: pointer; border-radius: 50%; border: 1px solid #fff}
.Popup .MoreInfo .ps__rail-y              { left: 0; display: none; }
.Popup .scrollbar-container               { display: flex; background: #fff; border-radius: 4px; }

.CardFlip                                 { position: absolute; transition: .4s ease-in-out; }
.CardFlip .CardElement                    { max-width: 100%; max-height: 100%; }
.CardFlip .CardBack, .CardFlip .CardFront { display: block; transition-timing-function: cubic-bezier(.175, .885, .32, 1.275); transition-duration: .4s; transition-property: transform, opacity; }
.CardFlip .CardFront .CardElement         { min-width: 210px; }
.CardFlip .CardFront                      { transform: rotateY(0deg); height: 100%; width: 100%; position: absolute; z-index: -1; }
.CardFlip .CardBack                       { opacity: 0; height: 100%; width: 100%; transform: rotateY(-180deg); background: #fff; }

.Strengths .CardsList .Exit              { height: 85px; width: 85px; position: absolute; left: 0; right: 0; margin: 15px auto 0; cursor: pointer; text-align: center; background:url('../images/Img_Wheel.jpg') no-repeat center; background-size: cover; }

.Strengths .CardsList          { position: absolute; top: 50%; transform: translate(0, -50%); left: 0; right: 0; margin: 0 auto;  width: 100%; }
.Strengths .CardsList.Disabled { opacity: 0; }

.Color0 .CardsTitle       { color: #2B9B52 }
.Color0 .CardTitle        { color: #ffffff }
.Color0 .CardsTitle .Lightning, 
.Color0 .Icon svg,
.Color0 .StrengthIcon     { fill: #2B9B52 }
.Color0 svg .BorderColor  { stroke: #2B9B52 }
.Color1 .CardsTitle       { color: #E28724 }
.Color1 .CardTitle        { color: #ffffff }
.Color1 .CardsTitle .Lightning, 
.Color1 .Icon svg,
.Color1 .StrengthIcon     { fill: #E28724 }
.Color1 svg .BorderColor  { stroke: #E28724 }
.Color2 .CardsTitle       { color: #0265BF }
.Color2 .CardTitle        { color: #ffffff }
.Color2 .CardsTitle .Lightning, 
.Color2 .Icon svg,
.Color2 .StrengthIcon     { fill: #0265BF }
.Color2 svg .BorderColor  { stroke: #0265BF }
.Color3 .CardsTitle       { color: #A32986 }
.Color3 .CardTitle        { color: #ffffff }
.Color3 .CardsTitle .Lightning, 
.Color3 .Icon svg,
.Color3 .StrengthIcon     { fill: #A32986 }
.Color3 svg .BorderColor  { stroke: #A32986 }

.Portal .MoreInfo .CardElement { background: #fff; }

.Slide.Hidden                     { visibility: hidden; }
.Strengths .CircleContainer       { max-width: 1200px; height: 500px; margin: 0 auto; position: relative; margin-top: 0px; }
.Strengths .Circle                { width: 500px; height: 500px; display: flex; flex-direction: column; justify-content: space-between; margin: auto; overflow: hidden; transition: 0.3s ease-in-out; }
.Strengths .Circle .Transparent   { fill: transparent }
.Strengths .Circle svg            { width: 234px; height: 234px; margin: 8px; transition: 0.3s ease-in-out; }
.Strengths .Circle path           { cursor: pointer; transition: 0.3s ease-in-out }
.Strengths .Circle svg text       { fill: #fff; text-transform: uppercase; transition: 0.3s ease-in-out; }
.Strengths .Circle .Description   { width: 135px; font-size: 1.0rem; line-height: 1.2rem; text-transform: uppercase; top: 0; color: #fff; font-family: Arial, Helvetica, Verdana; letter-spacing: 2px; text-align: center; position: absolute; pointer-events: none; transition: 0.3s ease-in-out; }
.Strengths .Circle .Curved        { font-family: 'Arvo', Arial, Helvetica, Verdana; font-size: 1.5rem; pointer-events: none; transition: 0.3s ease-in-out;  }
.Strengths .Right                 { margin-left: 0; transition: 0.3s ease-in-out; }
.Strengths .CircleInfo            { font-family: 'Arvo', Arial, Helvetica, Verdana; text-align: center; font-weight: 500; text-transform: uppercase; line-height: 0.5; font-size: 1.6rem; position: absolute; top: 50%; left: 50%; text-align: center; transform: translate(-50%, 0);  transition: 0.3s ease-in-out;}

.Strengths .Circle .Description:nth-child(1) { transform: translate(86px, 126px) }
.Strengths .Circle .Description:nth-child(2) { transform: translate(290px, 120px) }
.Strengths .Circle .Description:nth-child(3) { transform: translate(86px, 350px) }
.Strengths .Circle .Description:nth-child(4) { transform: translate(290px, 360px) }

.Strengths .Circle .cls-1 { fill: #2B9B52; fill-rule: evenodd; }
.Strengths .Circle .cls-2 { fill: #E28724; fill-rule: evenodd; }
.Strengths .Circle .cls-3 { fill: #0265BF; fill-rule: evenodd; }
.Strengths .Circle .cls-4 { fill: #A32986; fill-rule: evenodd; }

.Strengths .Circle.Out                 { width: 100%; height: 100%; }
.Strengths .Circle.Out .Description    { opacity: 0; }
.Strengths .Circle.Out svg             { opacity: 0.4; width: 85px; height: 85px; }
.Strengths .Circle.Out svg.Active      { opacity: 1; }
.Strengths .Circle.Out .CircleInfo     { opacity: 0; }
.Strengths .Circle.Out svg text        { opacity: 0; }
.Strengths .Circle.Out .Description    { opacity: 0; }

/* Confirm */
.Confirm .SelectedCards                               { margin-bottom: 20px; }
.Confirm .SelectedCards .Card                         { display: inline-block; margin-right: 10px; height: 140px;	width: 100px; overflow: hidden; text-align: center; border: 1px dashed #D5D1D1; text-align: center; padding-top: 19px; border: none; border-radius: 3px; margin-bottom: 10px; }
.Confirm .SelectedCards .Card.Color0                  { background-color:#2B9B52; }
.Confirm .SelectedCards .Card.Color1                  { background-color:#E28724; }
.Confirm .SelectedCards .Card.Color2                  { background-color:#0265BF; }
.Confirm .SelectedCards .Card.Color3                  { background-color:#A32986; }
.Confirm .SelectedCards .Card img                     { height: 54px; }
.Confirm .SelectedCards .Card .CardTitle              { margin-top: 10px; color: #fff; display: table; width: 100%; height: 40px; }
.Confirm .SelectedCards .Card .CardTitle .CellSmall   { display: table-cell; vertical-align: middle; text-align: center; font-size: 1.2rem; padding: 0px 5px; }
.Confirm .Block                                       { max-width: 800px; }

.LeftCellConfirmPage                                  { vertical-align: bottom !important; }

.ButtonsConfirmPage                                   { display: flex; flex-direction: column; width: 100%; align-items: center; justify-content: center; margin-bottom: 12px; }
.ButtonsConfirmPage .BtnConfirm                       { }
.ButtonsConfirmPage .BtnChange                        { margin-top: 40px; }


/* Thanks */
.Thanks                                     { }
.Thanks .Block                              { max-width: 800px; }
.Thanks .CheckboxRow                        { color: #333333; font-size: 1.4rem; line-height: 2.2rem; padding: 0px; margin-bottom: 20px; }
.Thanks .CheckboxHideShow                   { }
.Thanks label                               { font-size: 1.4rem }
.Thanks .LinkRow                            { color: #333333; font-size: 1.4rem; line-height: 2.2rem; padding: 0px; margin-bottom: 20px; }
.Thanks .LinkRow u                          { cursor: pointer; }
.Thanks .LinkRow b                          { font-weight: bold }
.Thanks .LinkRowHideShow                    { margin-bottom:20px; border: 1px solid #e0e0e0; background-color: #fff; padding:30px;}
.Thanks .LinkRowHideShow .Table             { display:table; width:100%; }
.Thanks .LinkRowHideShow .Table .CellOne    { display:table-cell; vertical-align:middle; font-size: 1.4rem; line-height: 2.2rem;}
.Thanks .LinkRowHideShow .Table .CellOne b  { font-weight: bold; }
.Thanks .LinkRowHideShow .Table .CellTwo    { display:table-cell; vertical-align:middle; text-align:right; }
.Thanks .LinkRowHideShow .BtnConfirm        {}
.Thanks .FormField .ResponseMessage         { padding-top: 0 !important; height: 0; }
.Thanks .StructureTable .RightCell a        { color: #333 }
.Thanks .Signature                          { font-family: 'Caveat', cursive, Arial; font-size: 5.0rem; margin-top: 25px; color: #787e80; }

/* Unsubscribe */
.Unsubscribe .WhiteBox                    { text-align: center }

/* Video */
.Video              {}
.Video iframe       { width: 100%; height:400px;}

/* Tooltips */
.TooltipOverlay               { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 3; }
.rc-tooltip                   { position: absolute; width: 200px; margin-left: -100px; background: #767676; padding: 20px 11px; color: #fff; font-size: 1.4rem; z-index: 4; text-align: center; }
.rc-tooltip.IsDisabled        { display: none; }
.rc-tooltip .closeTooltip     { position: absolute; top: 3px; right: 3px; cursor: pointer; }
.rc-tooltip .closeTooltip svg { fill: #fff; width: 10px; height: 10px; }
.rc-tooltip.Bottom            { margin-top: -10px; }
.rc-tooltip-hidden            { display: none; }
.rc-tooltip.rc-tooltip-placement-bottom {  }
.rc-tooltip.rc-tooltip-placement-bottom:after      { content: ''; position: absolute; width: 0; height: 0; border-style: solid; border-width: 0 15px 15px 15px; border-color: transparent transparent #767676 transparent; top: -15px; left: 85px; }
.rc-tooltip.rc-tooltip-placement-top:after         { content: ''; position: absolute; width: 0; height: 0; border-style: solid; border-width: 15px 15px 0 15px; border-color: #767676 transparent transparent transparent; bottom: -15px; left: 85px; }
.rc-tooltip.rc-tooltip-placement-left:after        { content: ''; position: absolute; width: 0; height: 0; border-style: solid; border-width: 15px 0 15px 15px; border-color: transparent transparent transparent #767676; right: -15px; top: 50%; transform: translate(0, -50%); }
.rc-tooltip.rc-tooltip-placement-right:after       { content: ''; position: absolute; width: 0; height: 0; border-style: solid; border-width: 15px 15px 15px 0; border-color: transparent #767676 transparent transparent; left: -15px; top: 50%; transform: translate(0, -50%); }

/* swiper */
.swiper-container                                       { min-height: 290px; }
.swiper-button-next, .swiper-button-prev                {}
.swiper-button-prev                                     { background: url('../images/Icon_Arrow_02.png') no-repeat center #000 !important; background-size: 25% !important; height: 40px !important; width: 40px !important; border-radius: 50%; }
.swiper-button-next                                     { background: url('../images/Icon_Arrow_01.png') no-repeat center #000 !important; background-size: 25% !important; height: 40px !important; width: 40px !important; border-radius: 50%; }
.swiper-pagination                                      { display: none; }


/* Accessibility */
.Accessibility                                    { }
.Accessibility p                                  { color: #000000; }
.Accessibility h3                                 { color: #000000; }
.Accessibility ul li                              { color: #000000; }
.Accessibility .Breadcrumb a                      { color: #000000; }
.Accessibility .Form .FormBox .FormLabel          { color: #000000; }   
.Accessibility .Form .FormBox .FormField .Input   { color: #000000; }  
  
.Accessibility .SiteFooter ul li                            { color: #000; }
.Accessibility .SiteFooter ul li a                          { color: #000; }
.Accessibility .SiteFooter .Credits                         { color: #000; }

.Accessibility .Welcome .Image .HoverTxt                    { background: #000000; }
.Accessibility .Thanks .Image .HoverTxt                     { background: #000000; }

.Accessibility .Instructions .FullInfo                      { color: #000; }

.Accessibility .Portal .MoreInfo ul li                      { color: #000000; }

.Accessibility .Thanks .CheckboxRow         { color: #000000; }
.Accessibility .Thanks .LinkRow             { color: #000000; }
.Accessibility .Thanks .Signature           { color: #000000; }

.Accessibility .Btn a, .Accessibility .Btn span, .Accessibility .Btn input  { background-color: #215E2F; }

.Accessibility .VisibleLoader             { background-color: #215E2F !important; }

.PopupCenter                              { margin: auto; }
.Instructions                             { transition: .2s ease-in-out; position: absolute; width: 500px; overflow: hidden; background: #fff; }
.Instructions .Container                  { transition: .2s ease-in-out; position: relative; border-radius: 3px; padding: 0; height: auto; background: #fff; }
.Instructions .FakeButton                 { transition: .2s ease-in-out; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background: #fff; border-radius: 50%; }
.Instructions .FakeButton svg             { fill: #333; width: 40px; height: 40px; padding: 9px; box-sizing: border-box; }

.Header .Open                             { opacity: 0; transition: 0s ease-in-out; transition-delay: .3s; }
.Instructions .Title                      { text-align: center; padding: 30px 0 0; font-size: 3rem; font-family: 'Arvo', serif, Arial, Helvetica, Verdana; }
.Instructions .Image img                  { width: 100%; border-bottom: 1px solid #fff; }
.Instructions .Subtitle                   { padding: 0 30px; margin: 30px 0 10px; font-size: 1.4rem; font-weight: 600; line-height: 1.4;  font-family: Arial, Helvetica, Verdana; text-transform: uppercase; letter-spacing: 2px; color: #c88064; }
.Instructions .FullInfo                   { font-size: 1.4rem; line-height: 1.8; padding: 0 30px; color: #787e80; }
.Instructions .FullInfo b                 { font-weight: bold; }
.Instructions .Index                      { margin: 25px 0px; display: flex; justify-content: center; }
.Instructions .Dot                        { width: 7px; height: 7px; border-radius: 50%; border: 1px solid #c88064; margin: 0 3px; }
.Instructions .Dot.Full                   { background: #c88064; }
.Instructions .PopUpButton                { margin: 0px 0px 40px 0px; display: block; width: 100%; text-align: center; }

.SuccessMessage                           { color: #2B9B52; height: 0 }

.NotFound                                 { display: flex; height: calc(100% - 40px); flex-direction: column; justify-content: center; align-items: center }
.NotFound img                             { width: 80px; height: 80px; margin-bottom: 20px; }
.NotFound a                               { color: #333; }

.Loader,
.Loader:before,
.Loader:after       { border-radius: 50%; width: 1em; height: 1em; animation-fill-mode: both; animation: load7 1.8s infinite ease-in-out; }
.Loader             { color: #ffffff; font-size: 10px; margin: auto; position: relative; text-indent: -9999em; transform: translateZ(0) translateY(-7px); animation-delay: -0.16s; }
.Loader:before,
.Loader:after       { content: ''; position: absolute; top: 0; }
.Loader:before      { left: -1.5em; animation-delay: -0.32s; }
.Loader:after       { left: 1.5em; }
.Loader             { }
.HiddenLoader       { display: none }
.VisibleLoader      { display: block; width: 100%; height: 100%; animation-delay: 0.5s; animation-name: fadein; animation-duration: 0.2s; animation-fill-mode: forwards; background-color: #36984a; margin-top: -14px;}
.LoaderContainer    { }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1em 0 -.5em;
  }
  40% {
    box-shadow: 0 1em 0 0;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}