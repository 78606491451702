/* Global */
@media (max-width: 1024px) {
  .Main {
    min-height: calc(100vh - 100px);
  }
}
@media (max-width: 768px) {
  .HeaderFixed .Header {
    position: relative;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
}

@media (max-width: 370px) {
  .Header .BtnPlusInfo {
    margin: 0 5px;
  }
  .Header .Languages {
    width: 130px;
  }
}

/* Register */
@media (max-width: 768px) {
  .Register .InputGroup {
    display: block;
  }
  .InputGroup.Cell:first-child {
    padding-right: 0;
  }
  .InputGroup.Cell:last-child {
    padding-left: 0;
  }
}

/* Strengths */
@media (max-width: 1200px) {
  .Strengths .TopStrengths .Subtitle {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .Strengths .TopStrengths .Subtitle_01 {
    padding-right: 20px;
  }
  .Strengths .TopStrengths .Subtitle_02 {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .Header.Fixed {
    position: relative;
  }
  .Strengths .CircleContainer {
    height: 400px;
    transition: 0.2s ease-in-out;
  }
  .StrengthsTable .StrengthsContent {
    height: auto;
  }
  .Strengths .Circle.Out svg {
    height: 40px;
    width: 40px;
  }
  .Strengths .Circle {
    width: 400px;
    height: 400px;
    position: relative;
  }
  .Strengths .Circle svg {
    margin: 4px;
    height: 192px;
    width: 192px;
  }
  .Strengths .CircleInfo {
    font-size: 1.4rem;
  }
  .Strengths .CardsTitle {
    padding: 0px 0px 3px 0px;
  }
  .Strengths .CardsSubtitle {
    letter-spacing: 1px;
    font-size: 1.1rem;
  }
  .Strengths .CardsList .Exit {
    margin: 5px auto 0;
    height: 50px;
    width: 50px;
  }

  .Strengths .NewCards .CardIcon img {
    height: 26px;
  }
  .Strengths .NewCards .Title {
    font-size: 6px;
    margin-top: 3px;
  }
  .Strengths .TopStrengths {
    height: auto;
    min-width: 320px;
    padding: 10px 0;
  }
  .Strengths .TopStrengths .Table {
    padding-bottom: 0px;
  }
  .Strengths .PickedCards { display: table; margin: 0 auto 10px auto; }

  .Strengths .PickedCards .Card {
    height: 70px;
    width: 46px;
  }

  .Strengths .Placeholder { height: 166px }

  .Strengths .Circle .Description               { width: 115px; font-size: 0.8rem; letter-spacing: 0.5px; }

  .Strengths .Circle .Description:nth-child(1)  { transform: translate(65px, 95px) }
  .Strengths .Circle .Description:nth-child(2)  { transform: translate(220px, 90px) }
  .Strengths .Circle .Description:nth-child(3)  { transform: translate(65px, 280px) }
  .Strengths .Circle .Description:nth-child(4)  { transform: translate(220px, 290px) }
  
}

@media (max-width: 768px) {
  .Strengths .Placeholder,
  .CookieBar .Strengths .Placeholder {
    height: 0px;
  }
  .Strengths .TopStrengths .BtnApply {
    top: -68px;
  }
  .Strengths .TopStrengths .Subtitle_01 {
    padding-right: 0px;
  }
  .Strengths .TopStrengths .Subtitle_02 {
    padding-left: 0px;
  }
  .Strengths .TopStrengths .Table {
    display: block;
  }
  .Strengths .TopStrengths .Table .Cell {
    display: block;
  }
  .Strengths .TopStrengths { position: relative; top: 0; }
}

@media (max-width: 620px) {
  .MoreInfo {
    width: 100%;
    overflow-x: hidden;
  }
  .Instructions .Container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .Strengths .CardElement .BackgroundIcon,
  .CardFlip .CardFront .CardElement .BackgroundIcon {
  }
  .CardElement .BackgroundIcon img {
    width: 300%;
  }
  .CardElement .CardTitle {
    margin-bottom:10px;
    margin-top: 65px;
  }
  .CardElement .CardInfo  {
  }
}

@media (max-width: 480px) {
  .Strengths .Circle {
    width: 320px;
    height: 320px;
  }
  .Strengths .Circle svg {
    margin: 0px;
    height: 154px;
    width: 154px;
  }
  .Strengths .CircleInfo {
    font-size: 1.2rem;
  }
  .Strengths .TopStrengths .Subtitle {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .Strengths .CardsTitle {
    padding: 5px 0 5px;
    font-size: 2rem;
  }
  .Strengths .CardsTitle svg {
    display: none;
  }
  .Strengths .CardsSubtitle {
    display: none;
  }
  .Strengths .Circle.Out {
    height: 320px;
}

  .Strengths .CardCell {
    width: 200px;
  }
  .Strengths .CardElement,
  .CardFlip .CardFront .CardElement {
    max-width: 200px;
    min-width: 200px;
    min-height: 210px;
    padding: 15px 15px;
  }

  .Strengths .CardElement .Buttons {
    bottom: 15px;
  }

  .Strengths .CircleContainer {
    height: auto;
  }
  .Strengths .CardsList .Exit {
    width: 40px;
    height: 40px;
    margin: 10px auto 0;
  }

  .swiper-wrapper .swiper-slide:last-child .CardElement {
    margin-right: 10px;
  }

  .swiper-container { min-height: 210px; }

  .Strengths .Circle .Description               { width: 75px; font-size: 0.7rem; letter-spacing: 0px; }

  .Strengths .Circle .Description:nth-child(1)  { transform: translate(55px, 75px) }
  .Strengths .Circle .Description:nth-child(2)  { transform: translate(195px, 70px) }
  .Strengths .Circle .Description:nth-child(3)  { transform: translate(55px, 220px) }
  .Strengths .Circle .Description:nth-child(4)  { transform: translate(195px, 225px) }
}



@media (max-width: 360px) {
  .Strengths .PickedCards .Card {
    margin-right: 2px;
  }
}

/*
@media (max-height: 700px) {
  .Strengths .CardElement, .CardFlip .CardFront .CardElement {
    min-height: 210px;
  }
  .CardElement .BackgroundIcon img {
    width: 300%;
  }
  .CardElement .CardTitle {
    margin-bottom:10px;
    margin-top: 65px;
  }
}
*/

/* Structure Table */
@media (max-width: 1024px) {
  .StructureTable {
    display: block;
  }
  .StructureTable .RightCell {
    display: block;
  }
  .StructureTable .LeftCell {
    display: block;
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
  }
}

/* Welcome */
@media (max-width: 1200px) {
  .Welcome .Subtitle {
    margin: 0px 0px 0px -20px;
    padding: 15px 60px 25px 20px;
    background-size: contain;
  }
}
@media (max-width: 1024px) {
  .Welcome .Table .Cell_01 {
    padding: 45px 0px 5px 0px;
  }
  .Welcome .Table .Cell_02 {
    display: none;
  }
  .BtnWelcomePage { 
    margin-bottom: 40px;
    justify-content: center; 
  }
}
@media (max-width: 768px) {
  .Welcome .Intro {
    font-size: 2rem;
    line-height: 3rem;
  }
  .Welcome .Title {
    font-size: 4rem;
  }
  .Welcome .Subtitle {
    font-size: 3.4rem;
    margin: 0px 0px 0px -15px;
    padding: 10px 60px 20px 15px;
    background-size: contain;
  }
}
@media (max-width: 480px) {
  .Welcome .Subtitle {
    font-size: 2.5rem;
    margin: -10px -15px 0px -15px;
    padding: 10px 30px 15px 15px;
  }
}

/* Register*/
@media (max-width: 1024px) {
  .BtnRegisterPage                { margin-top: 0px; }
  .TopSpaceRegisterPage           { margin-top: 0px; }
}

/* Form */
@media (max-width: 768px) {
  .Form .FormBox {
    display: block;
  }
  .Form .FormBox .FormLabel {
    display: block;
    width: 100%;
    padding: 0px 0px 10px 0px;
    text-align: left;
  }
  .Form .FormBox .FormField {
    display: block;
    width: 100%;
  }

  .Form2 .FormBox .FormLabel {
    width: 100%;
    line-height: 1.5em;
  }
  .Form2 .FormBox .FormField {
    width: 100%;
  }
  .Form2 .FormBox .FormButton {
    width: 100%;
    display: block;
  }
  .Form2 .FormBox .FormButton .BtnSend {
    margin: 20px 0px 0px 0px;
  }

  .BottomLinks .BtnLeft {
    float: none;
    display: block;
    margin-top: 10px;
  }
  .BottomLinks .BtnRight {
    float: none;
    display: block;
    text-align: center;
  }
}

/* Confirm */
@media (max-width: 1024px) {
  .Confirm .RightImage {
    display: none;
  }
  .ButtonsConfirmPage { 
    margin-top: 40px;
    }
}

@media (max-width: 768px) {
  .Confirm .BtnRed {
    width: 100%
  }
}

/* Thanks */
@media (max-width: 1024px) {
  .Thanks .RightImage {
    display: none;
  }
}
@media (max-width: 768px) {
  .Thanks .LinkRowHideShow .Table {
    display: block;
  }
  .Thanks .LinkRowHideShow .Table .CellOne {
    display: block;
    margin-bottom: 20px;
  }
  .Thanks .LinkRowHideShow .Table .CellTwo {
    display: block;
    text-align: right;
  }
  .Thanks .LinkRowHideShow .BtnConfirm {
    display: block;
  }
}

/* Popups */

@media (max-height: 790px) {
  .Instructions .Image     { text-align: center; }
  .Instructions .Image img { width: auto; height: 200px }
}


@media (max-width: 480px) {
  .Popup .MoreInfo .BackgroundImage {
    padding: 20px;
  }
}